<template>
    <div>
        <loading-screen :isLoading="isLoading"/>
        <div class="main-container modal-row">
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="card-title">
                                <div class="row gutters">
                                    <div class="col-xl-7 col-lg-6 col-md-5 col-sm-3 col-3">
                                        <label for="state">{{ t('NAV.ALLOTMENT') }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0 row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento' }"
                                         id="allotment"
                                         name="allotment" v-model="allotmentId"
                                         :options="allotments" @select="changeAllotment($event)"
                                         :disabled="!hastwoAllotments"/>
                            </div>
                            <div v-if="!widthPhone()" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 row">
                                <div class="font mt-2 ml-3" v-for="sub in subtitles" :key="sub.id">
                                    <i class="icon-label" :style="{'color': sub.backgroud_collor}"></i> {{ sub.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="block in blocks" :key="block">
                        <div class="accordion toggle-icons" id="toggleIcons">
                            <div class="accordion-container">
                                <div data-cy="public_lot_block_show" class="accordion-header"
                                     :id="'toggleIcons' + block.title">
                                    <a data-toggle="collapse" :data-target="'#toggleIconsCollapse' + block.title"
                                       aria-expanded="true"
                                       :aria-controls="'toggleIconsCollapse' + block.title"
                                       @click="indexLotsByAllotment(allotmentId, block.text)">
                                        {{ t('PROPERTIES.BLOCK') }} {{ block.text }}
                                    </a>
                                </div>

                                <div :id="'toggleIconsCollapse' + block.title" class="collapse"
                                     :class="{show: block.text == blockOpen}"
                                     :aria-labelledby="'toggleIcons' + block.title"
                                     :data-parent="'#toggleIcons' + block.title">
                                    <div class="accordion-body">
                                        <div class="text-center"
                                             v-if="isLoadingLots && (isLoadingLots.block == block.text && isLoadingLots.isLoading)">
                                            <div class="spinner-border m" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="row gutters">

                                            <div v-for="lot in lots[block.text]" :key="lot"
                                                 class="col-xl-1 col-lg-4 col-md-6 col-sm-6 col-6 text-center">
                                                <div class="card" :id="lot.id"
                                                     :style="{'background-color': lot.status.backgroud_collor}">
                                                    <a href="#" data-target="#modalShow" data-toggle="modal"
                                                       @click="setShowModal(lot)">
                                                        <h3 class="mt-1" :style="{'color': lot.status.text_collor}">
                                                            {{ lot.name }}</h3>
                                                        <p :style="{'color': lot.status.text_collor}">{{
                                                                lot.total_area
                                                            }} m²</p>
                                                        <p v-if="permissions.hasShowValuesOfLot"
                                                           class="font-weight-bold"
                                                           :style="{'color': lot.status.text_collor}">
                                                            {{ toCurrency(lot.price) }}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-if="!blocks">
                        <div class="card-header"></div>
                        <div class="card-body pt-0">
                            <div class="text-center">
                                <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('LOTS.INDEX_TITLE'), os: 'os'}) }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :titleModal="modalTitle" :modalLarge="modalLarge" :modal-extra-large="whichModal === 2"
               :modal-xl="whichModal !== 2">
            <template v-slot:title>{{ t(modalTitle) }}</template>

            <form @submit.prevent="create" v-if="whichModal === 1">
                <div class="modal-body" v-if="user">
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label>{{ t('LOTS.ALLOTMENT') }}: </label>
                                <label class="ml-1 color-grey">{{ nameAllotment }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label>{{ t('LOTS.BLOCK') }}: </label>
                                <label class="ml-1 color-grey">{{ modalBlock }}</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label>{{ t('LOTS.LOT') }}: </label>
                                <label class="ml-1 color-grey">{{ modalLot }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" v-if="user">
                            <div class="form-group">
                                <label for="email" class="required">{{ t('GENERAL.EMAIL') }}: </label>
                                <input type="text" id="email" name="email" v-model="user.email"
                                       data-cy="reserve_modal_validation_email" class="form-control"
                                       @keyup="clearMail()" placeholder="nome@email.com">
                                <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label for="password" class="required">{{ t('GENERAL.PASSWORD') }}: </label>
                                <input type="password" id="password" v-model="user.password" name="password"
                                       class="form-control" placeholder="Senha"
                                       @keyup="clearPassword()"/>
                                <div class="validation" id="invalidpassword" style="display: none"
                                     data-cy="reserve_modal_validation_password">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex">
                    <button class="btn btn-primary mr-auto" @click.stop.prevent="openRecoverPasswordPage">
                        {{ t('GENERAL.RECOVER_PASSWORD') }}
                    </button>
                    <button class="btn btn-secondary mr-2" data-dismiss="modal" type="button">
                        {{ t('ACTIONS.CLOSE') }}
                    </button>
                    <button class="btn btn-primary" @click.stop.prevent="create">
                        {{ t('ACTIONS.CONFIRM') }}
                    </button>
                </div>
            </form>

            <iframe style="height: 500px;" :src="srcIframe" v-if="whichModal === 2"/>
            <div class="modal-footer right-content" v-if="whichModal === 2">
                <button data-cy="lot_index_simulate_close" class="btn btn-secondary" data-dismiss="modal" type="button">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
            </div>
        </modal>

        <div class="modal fade" id="modalShow" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"
             style="position: fixed;">
            <button type="button" class="closeX" id="closeModal" data-dismiss="modal" aria-label="Close"
                    hidden></button>
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content" v-if="status && lotSelected">

                    <div class="modal-body text-center">
                        <h4>{{ t('LOTS.LOT_INFORMATION') }}</h4>

                        <div class="info-stats4 w-100 full-border">
                            <div class="row gutters">

                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"/>
                                <div class="info-lot col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"
                                     :style="{'background-color': status.backgroud_collor, color: status.text_collor}">
                                    <h3>{{ lotSelected.name }}</h3>
                                    <h5>{{ status.name }}</h5>
                                </div>

                                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"/>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                    <i class="icon-eye btn pointer" v-if="permissions.hasViewPermission"
                                       data-toggle="modal"
                                       @click="setViewModal(lotSelected?.lot?.id)" data-target="#showViewLote"/>
                                </div>
                                <h4 class="font-weight-bold mt-1" style="width: 100%">{{
                                        toCurrency(lotSelected.price)
                                    }}</h4>
                                <div class="sale-num col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                                <div class="sale-num col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"><p class="secondary">
                                    Quadra
                                    {{ lotSelected.block }}</p></div>
                                <div class="sale-num col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"><p class="secondary">
                                    {{ lotSelected.total_area }}m²</p></div>
                                <div class="sale-num col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                            </div>
                        </div>
                        <div class="space-between" :style="{'justify-content': formartButtons()}">
                            <button data-cy="lot_show_reserve" type="button" data-toggle="modal"
                                    class="btn btn-primary mr-1"
                                    data-target="#customModalTwo"
                                    v-if="permissions.hasReservePermission && status.type === 0"
                                    @click="setReserveModal">
                                {{ t('LOTS.RESERVE') }}
                            </button>
                            <button data-cy="lot_show_simulate" type="button" data-toggle="modal"
                                    class="btn btn-primary mr-1"
                                    data-target="#customModalTwo"
                                    v-if="status.type === 0 && permissions.hasSimulatePermission"
                                    @click="setCalculatorModal(lotSelected?.id)">
                                {{ t('GENERAL.SIMULATOR') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="teste">
            <div class="text-center mb-2" v-if="widthPhone()">
                <div class="fab" ontouchstart="">
                    <button class="main">
                        <i class="icon-typing"/>
                    </button>
                    <ul>
                        <li v-for="sub in subtitles" :key="sub.id">
                            <label for="opcao2">Opção 2</label>
                            <button id="opcao1" :style="{'background-color': sub.backgroud_collor + '!important'}">
                                {{ sub.name }}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
        <modal :modal-xl="true" :id-modal="'showViewLote'" :titleModal="modalTitle">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <div class="modal-row2 modal-height-max" style="overflow-x: hidden">
                <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="tab-content" id="myTabContent">
                                <div class="row gutters">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label for="allotment_id">{{ t('LOTS.ALLOTMENT') }}: </label>
                                            <input class="form-control" type="text" v-model="lotSelected.allotment_name"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <div class="form-group">
                                            <label for="state">{{ t('LOTS.BLOCK') }}: </label>
                                            <input type="text" class="form-control" v-model="lotSelected.block"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <div class="form-group">
                                            <label for="name">{{ t('LOTS.LOT') }}: </label>
                                            <input type="text" v-model="lotSelected.name" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutters">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                        <div class="form-group">
                                            <label for="status">{{ t('LOTS.SITUATION') }}: </label>
                                            <input type="text" class="form-control" id="status"
                                                   v-model="lotSelected.status_name"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="form-group">
                                            <label for="front_size">{{ t('LOTS.FRONT_CONFRONTATION') }}: </label>
                                            <input type="text" v-model="lotSelected.front_confrontation"
                                                   name="front_confrontation"
                                                   class="form-control" disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <div class="form-group">
                                            <label for="total_area">{{ t('LOTS.TOTAL_AREA') }}: </label>
                                            <input type="number" v-model="lotSelected.total_area" class="form-control"
                                                   step=".01"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <div class="form-group">
                                            <label for="price">{{ t('PROPERTIES.PRICE') }}: </label>
                                            <input v-model="lotSelected.price_formated" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutters">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                        <div class="form-group">
                                            <label for="front_size">{{ t('LOTS.MEASURE_FRONT') }}: </label>
                                            <input type="text" v-model="lotSelected.front_size" class="form-control"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                        <div class="form-group">
                                            <label for="back_size">{{ t('LOTS.MEASURE_BACK') }}: </label>
                                            <input type="text" v-model="lotSelected.back_size" class="form-control"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                        <div class="form-group">
                                            <label for="chamfer_size">{{ t('LOTS.MEASURE_CHANFER') }}: </label>
                                            <input type="text" v-model="lotSelected.chamfer_size" class="form-control"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <div class="form-group">
                                            <label for="right_size">{{ t('LOTS.MEASURE_RIGHT') }}: </label>
                                            <input type="text" v-model="lotSelected.right_size" class="form-control"
                                                   disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <div class="form-group">
                                            <label for="left_size">{{ t('LOTS.MEASURE_LEFT') }}: </label>
                                            <input type="text" v-model="lotSelected.left_size" class="form-control"
                                                   disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutters">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label for="background_confrontation">{{
                                                    t('LOTS.BACKGROUND_CONFRONTATION')
                                                }}: </label>
                                            <input type="text" id="background_confrontation"
                                                   v-model="lotSelected.background_confrontation"
                                                   name="background_confrontation" class="form-control" disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label for="right_confrontation">{{
                                                    t('LOTS.RIGHT_CONFRONTATION')
                                                }}: </label>
                                            <input type="text" id="right_confrontation"
                                                   v-model="lotSelected.right_confrontation"
                                                   name="right_confrontation" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutters">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label for="left_confrontation">{{ t('LOTS.LEFT_CONFRONTATION') }}: </label>
                                            <input type="text" id="left_confrontation"
                                                   v-model="lotSelected.left_confrontation"
                                                   name="left_confrontation" class="form-control" disabled>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label for="chamfer_confrontation">{{
                                                    t('LOTS.CHAMFER_CONFRONTATION')
                                                }}: </label>
                                            <input type="text" id="chamfer_confrontation"
                                                   v-model="lotSelected.chamfer_confrontation"
                                                   name="chamfer_confrontation" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer right-content">
                            <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal">
                                {{ t('ACTIONS.CLOSE') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import Modal from '@/components/modal';
import Public from '@/services/Public';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import Properties from '../../services/Properties';
import propertyTypes from '../../services/propertyTypes';
import BuildSimulatorUrl from '@/Helpers/BuildSimulatorUrl';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';

export default {
    name: 'publicLot',

    mixins: [Validate, BuildSimulatorUrl],

    components: {
        Modal,
        Select2,
        LoadingScreen,
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};

    },

    computed: {
        ...mapState({
            lotModified: 'lotModified',
        }),
    },

    data() {
        return {
            lots: [],
            user: {},
            status: null,
            blocks: null,
            modalLot: null,
            srcIframe: null,
            blockOpen: null,
            subtitles: null,
            lotSelected: {},
            isLoading: true,
            permissions: {},
            whichModal: null,
            allotments: null,
            modalTitle: null,
            modalBlock: null,
            modalLarge: null,
            propertyTypes: [],
            allotmentId: null,
            nameAllotment: null,
            isLoadingLots: null,
            propertiesStatus: [],
            hastwoAllotments: null,
            reserveTypesIndex: null,
            companyId: this.$route.params.companyId,
        };
    },

    watch: {
        lotModified: function () {
            if (this.lotModified[0]) {
                this.lotModified.forEach(lot => {
                    this.lots[lot.block]?.forEach((l, key) => {
                        if (l.name == lot.name) {
                            this.lots[lot.block][key] = lot;
                        }
                    });
                });
            }
        },
    },

    async mounted() {
        await this.listPropertyesType();
        await this.listPropertiesStatus();
        await this.checkPermission();
        this.indexSubtitles();
        this.setPublicAllotment();
        this.indexAllotment();
    },

    methods: {
        async listPropertyesType() {
            const companyId = this.$route.params.companyId;
            await propertyTypes.listPublic(companyId).then(resp => {
                this.propertiesType = resp.data;
            });
        },
        async listPropertiesStatus() {
            const companyId = this.$route.params.companyId;
            await Properties.listStatusCompletPublic(companyId).then(resp => {
                this.propertiesStatus = resp.data;
            });
        },

        checkPermission() {
            const companyId = this.$route.params.companyId;
            const allotmentId = this.$route.params.allotmentId;
            Public.checkPermissionPublicArea(companyId, allotmentId).then(resp => {
                if (!resp?.data?.permission) {
                    this.$router.push({name: 'Home'});
                    this.toast.error('Loteamento inativo na area pública');
                }
            }).catch(() => {
                this.$router.push({name: 'Home'});
                this.toast.error('Loteamento inativo na area pública');
            });
        },

        clearPassword() {
            if (this.user.password) {
                this.user.password = this.user.password.trim();
            }
            this.inputGeneric(this.user.password, 'password');
        },

        openRecoverPasswordPage() {
            window.open('/recuperar-senha', '_blank');
        },

        clearMail() {
            if (this.user.email) {
                this.user.email = this.user.email.trim();
                this.user.email = this.user.email.toLowerCase();
            }
            this.inputEmail(this.user.email);
        },

        indexSubtitles() {
            Public.indexSituation(this.companyId).then(resp => this.subtitles = resp.data.data);
        },

        setPublicAllotment() {
            const path = window.location.pathname.split('/');
            const allotmentId = path[(path.length - 1)];
            localStorage.setItem('publicAllotmentId', allotmentId);
        },

        indexAllotment(updateBlock = false) {
            Public.listAllotment(this.companyId).then(async resp => {
                this.allotments = resp.data;
                this.hastwoAllotments = this.allotments.length > 1;
                const allotment = this.allotments.find(a => a.id == this.$route.params.allotmentId);

                if (allotment) {
                    this.allotmentId = allotment.id;
                    this.nameAllotment = allotment.text;
                    await this.setPermissions(allotment.id);
                    await this.indexBlocks(allotment.id, updateBlock);
                } else {
                    this.isLoading = false;
                }
            }).catch(error => this.errorMsg(error));
        },

        async changeAllotment(allotment) {
            this.isLoading = true;
            await this.setPermissions(allotment.id);
            await this.indexBlocks(allotment.id, true, true);
            this.nameAllotment = allotment.text;
        },

        async setViewModal(lote_id) {
            this.$store.commit('changeLoading', true);
            this.modalTitle = 'LOTS.MODAL_TITLE.VIEW';
            document.getElementById('closeModal').click();

            await Public.LotShow(this.$route.params.companyId, lote_id).then(resp => {
                const properties = resp.data.properties;
                //  const allotments = properties.allotments;

                let statusP = this.propertiesStatus.find(i => i.id === properties.properties_status_id);
                if (statusP.can_select) {
                    let statusTrue = this.propertiesStatus.filter(i => i.can_select);
                    properties.properties_status = statusTrue;
                } else {
                    properties.properties_status = [statusP];
                }
                properties.properties_types = this.propertiesType;
                const datas = {
                    status_name: statusP.name,
                    allotment_name: this.allotments.find(i => i.id === properties.allotment_id).text,
                    front_confrontation: resp.data.front_confrontation,
                    price_formated: this.toCurrency(properties.price),
                    back_size: resp.data.back_size,
                    chamfer_size: resp.data.chamfer_size,
                    front_size: resp.data.front_size,
                    left_size: resp.data.left_size,
                    right_size: resp.data.right_size,
                    background_confrontation: resp.data.background_confrontation,
                    chamfer_confrontation: resp.data.chamfer_confrontation,
                    left_confrontation: resp.data.left_confrontation,
                    right_confrontation: resp.data.right_confrontation,
                };
                this.lotSelected = {...this.lotSelected, ...datas};
            }).catch(error => this.errorMsg(error))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        async setPermissions(allotmentId) {
            await Public.getAllotmentPermissions(allotmentId, this.companyId).then(resp => {
                if (resp.data) {
                    this.permissions.hasReservePermission = resp.data.action_reserve_lot_on_map;
                    this.permissions.hasSimulatePermission = resp.data.action_calculate_financing_on_map;
                    this.permissions.hasViewPermission = resp.data.action_view_lot_data_on_map;
                    this.permissions.hasShowValuesOfLot = resp.data.permissions_informations_lots_on_marketing;
                } else {
                    this.permissions = {};
                }
            }).catch(err => this.errorMsg(err));
        },

        async indexBlocks(allotmentId, updateBlock = false) {
            this.isLoading = true;

            this.blocks = null;
            this.blockOpen = null;

            await Public.blocksList(this.companyId, allotmentId)
                .then(resp => this.blocks = resp.data)
                .catch(error => this.errorMsg(error));

            if (!this.blocks.validations) {
                this.listLotsModified();
                this.blocks.forEach(block => block.title = block.text.replaceAll(' ', ''));

                this.blockOpen = this.blocks[0].text;
                this.indexLotsByAllotment(allotmentId, this.blockOpen, updateBlock);
            }

            this.isLoading = false;
        },

        listLotsModified() {
            this.$store.dispatch('ActionListLotModifiedPublic', this.companyId);
        },

        indexLotsByAllotment(allotmentId, blockId, updateBlock = false) {
            if (!this.lots[blockId] || updateBlock) {
                this.isLoadingLots = {block: blockId, isLoading: true};

                Public.lotsByAllotmentAndBlock(this.companyId, allotmentId, blockId)
                    .then(resp => {
                        if (resp.data?.lots?.length) {
                            const lots = resp.data.lots;
                            lots.forEach(i => {
                                i.status = this.propertiesStatus.find(s => s.id === i.properties_status_id);
                                i.properties_type_name = this.propertiesType.find(t => t.id === i.properties_type_id).text;
                            });
                            this.lots[blockId] = lots;
                        } else {
                            this.lots[blockId] = [];
                        }
                    })
                    .catch(error => this.errorMsg(error))
                    .finally(() => this.isLoadingLots.isLoading = false);
            }
        },

        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },

        setShowModal(lot) {
            this.whichModal = 1;
            this.lotSelected = lot;
            this.modalLarge = false;
            this.status = lot.status;
            this.modalLot = lot.name;
            this.modalBlock = lot.block;
        },

        formartButtons() {
            return !this.permissions.hasReservePermission ? 'end !important' : 'space-between !important';
        },

        setReserveModal() {
            this.modalTitle = 'RESERVE.MODAL_TITLE.ADD';
            document.getElementById('closeModal').click();
        },

        async setCalculatorModal(propertyId) {
            this.whichModal = 2;
            this.isLoading = true;
            this.modalLarge = true;
            document.getElementById('closeModal').click();
            this.modalTitle = 'LOTS.MODAL_TITLE.SIMULATE';

            await this.buildSimulatorUrl(propertyId).then(url => this.srcIframe = url).catch(err => this.errorMsg(err))
                .finally(() => this.isLoading = false);
        },

        create() {
            if (this.checkForm()) {
                this.isLoading = true;

                const form = {
                    username: this.user.email,
                    password: this.user.password,
                    propertyId: this.lotSelected.id,
                };

                Public.ReserveCreate(this.companyId, form).then(() => {
                    this.indexLotsByAllotment(this.allotmentId, this.modalBlock, true);
                    document.getElementById('closeX').click();
                    this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
                }).catch(error => this.errorMsg(error))
                    .finally(() => this.isLoading = false);
            }
        },

        checkForm() {
            this.invalid = [];

            this.emailValidate(this.user.email);
            this.validateGeneric(this.user.password, 'password');

            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style>
.paddin {
    padding-left: 1rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
}

body.authentication {
    display: block;
    align-items: flex-start;
    justify-content: left;
    background: #eff1f5;
}

.modal-row {
    max-height: 100%;
    overflow-y: auto;
}

.modal-row::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.info-lot {
    border-radius: 7px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.color-grey {
    color: #727272;
}

.font {
    font-size: 0.8rem;
}

.fab {
    position: fixed;
    bottom: 5px;
    right: 10px;
}

.fab button {
    cursor: pointer;
    width: 100%;
    height: 45px;
    border-radius: 20px;
    background-color: #383737;
    border: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
    font-size: 0.9rem;
    color: black;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #0e5e4e !important;
    right: 0;
    bottom: 0;
    z-index: 20;
}

.fab ul {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    list-style: none;
    z-index: 10;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab ul li {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: -10%;
    opacity: 0;

    -webkit-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out;
}

.fab ul li label {
    margin-right: 10px;
    white-space: nowrap;
    display: block;
    margin-top: 10px;
    padding: 5px 8px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    border-radius: 3px;
    height: 18px;
    font-size: 16px;
    pointer-events: none;
    opacity: 0;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus {
    outline: none;
    background-color: #000000;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
}

.fab button.main:active + ul,
.fab button.main:focus + ul {
    bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li {
    margin-bottom: 8px;
    opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label {
    opacity: 1;
}
</style>
